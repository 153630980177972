.Cannban-Header {
  position: fixed;
  z-index: 1;
  width: calc(100% - 80px);
}

.Cannban-Header-login  {
  position: fixed;
  z-index: 1;
  width: 100%;
}